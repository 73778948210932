import React from 'react'
import { GrayCardIcon } from '../../../assets/GrayCardIcon'
import { ApplePayIcon } from '../../../assets/ApplePayIcon'
import { GPayIcon } from '../../../assets/GPayIcon'

interface PaymentIconsProps {
  paymentType: string
}

interface PaymentOption {
  key: string
  icon: React.JSX.Element
  label: string
}

export const PaymentIcons = ({ paymentType }: PaymentIconsProps) => {
  const paymentOptions: PaymentOption[] = [
    { key: 'card', icon: <GrayCardIcon />, label: 'Card' },
    { key: 'applePay', icon: <ApplePayIcon />, label: 'Apple Pay' },
    { key: 'googlePay', icon: <GPayIcon />, label: 'Google Pay' }
  ]

  const selectedOption = paymentOptions.find(
    (option) => option.key === paymentType || option.label === paymentType
  )

  if (!selectedOption) return null

  const { icon: IconComponent, label } = selectedOption

  return <div aria-label={label}>{IconComponent}</div>
}
