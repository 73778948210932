const PAYMENT_METHOD_CONFIG_BASE_URL =
  '/api/service/payment-method-configs/v1/developer/payment-method-configurations'

const PAYMENT_METHOD_DOMAIN_BASE_URL =
  '/api/service/payment-method-domains/v1/developer/payment-method-domains'

export const PAYMENT_METHOD_CONFIG = {
  PATH: `${PAYMENT_METHOD_CONFIG_BASE_URL}`
}

export const PAYMENT_METHOD_DOMAIN = {
  PATH: `${PAYMENT_METHOD_DOMAIN_BASE_URL}`
}
