import { useEffect, useState, useCallback, useMemo } from 'react'
import { Partner } from '@toasttab/depot-banquet-props'
import { PaymentMethodConfig } from '../typedefs/payment-method-configs/PaymentMethodConfigs'
import { PAYMENT_METHOD_CONFIG } from '../api/config'

export const useFetchPaymentMethodConfigs = (partner: Partner) => {
  const [pmcList, setPmcList] = useState<PaymentMethodConfig[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const fetchConfigs = useCallback(async () => {
    setLoading(true)
    setError(null)

    try {
      const response = await fetch(`${PAYMENT_METHOD_CONFIG.PATH}?limit=100`, {
        headers: { 'toast-organization-guid': partner.guid }
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      setPmcList(data.paymentMethodConfigurations)
    } catch (err) {
      setError('Failed to fetch payment method configurations')
      setPmcList([])
    } finally {
      setLoading(false)
    }
  }, [partner.guid])

  useEffect(() => {
    fetchConfigs()
  }, [fetchConfigs])

  const filteredPmcList = useMemo(() => {
    return pmcList.map((config) => {
      const paymentMethods: string[] = []
      let active = ''
      const methods = [
        { name: 'Card', preference: config.card.displayPreference },
        { name: 'Apple Pay', preference: config.applePay.displayPreference },
        { name: 'Google Pay', preference: config.googlePay.displayPreference }
      ]

      if (config.active === true) {
        active = 'Yes'
      } else {
        active = 'No'
      }

      methods.forEach((method) => {
        if (method.preference === 'ON') {
          paymentMethods.push(method.name)
        }
      })

      return {
        id: config.id,
        active: active,
        name: config.name,
        paymentMethods
      }
    })
  }, [pmcList])

  return { pmcList, filteredPmcList, loading, error, fetchConfigs }
}
