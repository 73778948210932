import { useCallback } from 'react'
import { Partner } from '@toasttab/depot-banquet-props'
import { PAYMENT_METHOD_DOMAIN } from '../api/config'
import useApi from './useApi'

interface CreatePaymentMethodDomainResponse {
  domain: string
}

export const useCreatePaymentMethodDomain = (partner: Partner) => {
  const { loading, error, data, fetchApi } =
    useApi<CreatePaymentMethodDomainResponse>(
      PAYMENT_METHOD_DOMAIN.PATH,
      'POST',
      partner.guid
    )

  const createPaymentMethodDomain = useCallback(
    (config: any) => {
      return fetchApi(config)
    },
    [fetchApi]
  )

  return { loading, error, data, createPaymentMethodDomain }
}
