import { useState, useCallback } from 'react'
import {
  SavePaymentMethodConfig,
  PaymentMethodConfig
} from '../typedefs/payment-method-configs/PaymentMethodConfigs'
import { Partner } from '@toasttab/depot-banquet-props'
import { PAYMENT_METHOD_CONFIG } from '../api/config'

export const useUpdatePaymentMethodConfig = (partner: Partner) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<PaymentMethodConfig | null>(null)

  const updatePaymentMethodConfig = useCallback(
    async (config: SavePaymentMethodConfig) => {
      setLoading(true)
      setError(null)

      try {
        const { id, ...data } = config
        const response = await fetch(`${PAYMENT_METHOD_CONFIG.PATH}/${id}`, {
          method: 'POST',
          headers: {
            'toast-organization-guid': partner.guid,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })

        if (!response.ok) {
          const errorText = await response.text()
          throw new Error(
            `Failed to save payment method config: ${response.status} ${response.statusText} - ${errorText}`
          )
        }

        const responseData = await response.json()
        setData(responseData)
      } catch (err) {
        setError((err as Error).message)
      } finally {
        setLoading(false)
      }
    },
    [partner.guid]
  )

  return { loading, error, data, updatePaymentMethodConfig }
}
