import { useEffect } from 'react'
import { Partner } from '@toasttab/depot-banquet-props'
import { PAYMENT_METHOD_DOMAIN } from '../api/config'
import useApi from './useApi'

interface PaymentMethodDomain {
  id: string
  name: string
}

interface FetchPaymentMethodDomainResponse {
  paymentMethodDomains: PaymentMethodDomain[]
}

export const useFetchPaymentMethodDomain = (partner: Partner) => {
  const { loading, error, data, fetchApi } =
    useApi<FetchPaymentMethodDomainResponse>(
      `${PAYMENT_METHOD_DOMAIN.PATH}?limit=100`,
      'GET',
      partner.guid
    )

  useEffect(() => {
    fetchApi()
  }, [fetchApi])

  return {
    pmdList: data?.paymentMethodDomains || [],
    loading,
    error,
    fetchDomains: fetchApi
  }
}
