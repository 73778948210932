import React, { useState } from 'react'
import { Modal } from '@toasttab/buffet-pui-modal'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { Button } from '@toasttab/buffet-pui-buttons'
import { PAYMENT_METHOD_DOMAIN } from '../PaymentMethodsComponent/PaymentMethodConstants'

interface DomainModalComponentProps {
  modalIsOpen: boolean
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  onSave: (domain: string) => void
}

export const DomainModalComponent: React.FC<DomainModalComponentProps> = ({
  modalIsOpen,
  setModalIsOpen,
  onSave
}) => {
  const [domainName, setDomainName] = useState('')
  const [loading, setLoading] = useState(false)

  const closeModal = () => {
    setDomainName('')
    setModalIsOpen(false)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomainName(e.target.value)
  }

  const handleSave = async () => {
    setLoading(true)
    try {
      await onSave(domainName)
      closeModal()
    } catch (error) {
      //TODO will remove concole and update this to show a notification in the next ticket
      console.error('Error saving domain:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      className='text-default'
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      size='lg'
    >
      <Modal.Header>{PAYMENT_METHOD_DOMAIN.REGISTER_DOMAIN}</Modal.Header>
      <Modal.Body className='mt-4'>
        <TextInput
          label={PAYMENT_METHOD_DOMAIN.DOMAIN_LABEL}
          placeholder={PAYMENT_METHOD_DOMAIN.DOMAIN_PACEHOLDER}
          name='domain-name'
          value={domainName}
          size='lg'
          transparentBackground={true}
          onChange={handleInputChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='flex-grow sm:flex-none'
          variant='link'
          onClick={closeModal}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          className='flex-grow sm:flex-none'
          onClick={handleSave}
          disabled={loading || !domainName.trim()}
        >
          Register
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DomainModalComponent
