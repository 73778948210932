export const ApplePayIcon = () => {
  return (
    <svg
      width='40'
      height='25'
      viewBox='0 0 40 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='40' height='25' rx='2' fill='black' />
      <path
        d='M10.4816 7.5471C10.13 7.95243 9.5674 8.27212 9.00483 8.22645C8.93451 7.6784 9.20994 7.0961 9.53224 6.73644C9.88384 6.3197 10.4991 6.02284 10.9973 6C11.0559 6.57088 10.8273 7.13035 10.4816 7.5471ZM10.9914 8.33492C10.1768 8.28925 9.4795 8.78592 9.09274 8.78592C8.70011 8.78592 8.10825 8.35775 7.46364 8.36917C6.62566 8.38059 5.84627 8.84301 5.41849 9.57945C4.53948 11.0523 5.18994 13.2331 6.03965 14.432C6.45571 15.0257 6.95382 15.6765 7.61014 15.6537C8.23131 15.6308 8.47743 15.2598 9.22752 15.2598C9.98346 15.2598 10.2003 15.6537 10.8566 15.6422C11.5364 15.6308 11.9642 15.0485 12.3802 14.4548C12.8549 13.7812 13.0483 13.1246 13.06 13.0904C13.0483 13.079 11.7473 12.5937 11.7356 11.1323C11.7239 9.91056 12.7611 9.32826 12.808 9.29401C12.222 8.4491 11.3078 8.35775 10.9914 8.33492ZM15.697 6.67935V15.5794H17.1151V12.5366H19.0783C20.8714 12.5366 22.1313 11.3378 22.1313 9.60228C22.1313 7.86679 20.8949 6.67935 19.1251 6.67935H15.697ZM17.1151 7.84396H18.7501C19.9807 7.84396 20.6839 8.48335 20.6839 9.60799C20.6839 10.7326 19.9807 11.3777 18.7442 11.3777H17.1151V7.84396ZM24.7215 15.648C25.6122 15.648 26.4385 15.2084 26.8135 14.5119H26.8428V15.5794H28.1555V11.1494C28.1555 9.86489 27.1007 9.03711 25.4774 9.03711C23.9714 9.03711 22.858 9.87631 22.817 11.0295H24.0945C24.1999 10.4814 24.7215 10.1218 25.4364 10.1218C26.3037 10.1218 26.7901 10.5157 26.7901 11.2407V11.7317L25.0203 11.8344C23.3737 11.9315 22.4829 12.588 22.4829 13.7298C22.4829 14.883 23.403 15.648 24.7215 15.648ZM25.1024 14.5918C24.3464 14.5918 23.8659 14.2379 23.8659 13.6955C23.8659 13.1361 24.3289 12.8107 25.2137 12.7593L26.7901 12.6622V13.1646C26.7901 13.9981 26.0634 14.5918 25.1024 14.5918ZM29.9076 18C31.2906 18 31.9411 17.4862 32.5095 15.9277L35 9.12274H33.5584L31.8883 14.3806H31.859L30.1889 9.12274H28.7063L31.1089 15.6023L30.98 15.9962C30.7632 16.6641 30.4116 16.921 29.7846 16.921C29.6732 16.921 29.4564 16.9096 29.3685 16.8982V17.9657C29.4505 17.9886 29.8021 18 29.9076 18Z'
        fill='white'
      />
    </svg>
  )
}
