import { CardContainer } from '@toasttab/buffet-pui-card'
import { Button } from '@toasttab/buffet-pui-buttons'
import { AddIcon } from '@toasttab/buffet-pui-icons'
import { TableComponent } from '../ReusableComponents/TableComponent/TableComponent'
import { PAYMENT_METHOD_CONFIG } from '../PaymentMethodsComponent/PaymentMethodConstants'
import { useFetchPaymentMethodConfigs } from '../../hooks/useFetchPaymentMethodConfigs'
import { TableColumn } from '../ReusableComponents/TableComponent/TableComponentTypes'
import { PaymentIcons } from '../ReusableComponents/PaymentIcons/PaymentIcons'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { ConfigModalComponent } from './ConfigModalComponent'
import { useState } from 'react'
import { PaymentMethodConfig } from '../../typedefs/payment-method-configs/PaymentMethodConfigs'

export const ConfigurationComponent = () => {
  const { partner } = useDepotBanquetProps()
  const { pmcList, filteredPmcList, fetchConfigs } =
    useFetchPaymentMethodConfigs(partner)

  const configColumns: TableColumn[] = [
    { heading: 'Configuration Name', dataKey: 'name' },
    { heading: 'Configuration ID', dataKey: 'id' },
    {
      heading: 'Enabled payment methods',
      dataKey: 'paymentMethods',
      render: (paymentMethods) => (
        <div className='payment-icons-container'>
          {paymentMethods?.map((paymentType: string, index: number) => (
            <PaymentIcons key={paymentType + index} paymentType={paymentType} />
          ))}
        </div>
      )
    },
    { heading: 'Active?', dataKey: 'active' }
  ]

  const [modalData, setModalData] = useState<PaymentMethodConfig | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editMode, setEditMode] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setModalData(null)
  }

  const handleUpdateConfig = (rowData: PaymentMethodConfig) => {
    const configToUpdate = pmcList.find((config) => config.id === rowData.id)
    if (configToUpdate) {
      setEditMode(true)
      setModalData(configToUpdate)
      openModal()
    }
  }
  const handleCreateConfig = () => {
    setEditMode(false)
    openModal()
  }

  const handleFetchNewConfigs = () => {
    fetchConfigs()
  }

  return (
    <div>
      <CardContainer>
        <div className='flex justify-between mb-5'>
          <div>
            <h2 className='type-headline-5 font-bold'>
              {PAYMENT_METHOD_CONFIG.HEADING}
            </h2>
            <h3 className='type-subhead text-secondary mt-3'>
              {PAYMENT_METHOD_CONFIG.SUBHEADING}
            </h3>
          </div>
          <Button
            variant='secondary'
            size='sm'
            iconLeft={<AddIcon accessibility='decorative' />}
            onClick={handleCreateConfig}
          >
            {PAYMENT_METHOD_CONFIG.BUTTON_TEXT}
          </Button>
        </div>
        <TableComponent
          columns={configColumns}
          data={filteredPmcList}
          showModalButton={true}
          onModalButtonClick={handleUpdateConfig}
        />
      </CardContainer>
      <ConfigModalComponent
        modalIsOpen={isModalOpen}
        setModalIsOpen={closeModal}
        edit={editMode}
        modalData={modalData}
        fetchNewConfigData={handleFetchNewConfigs}
      />
    </div>
  )
}
