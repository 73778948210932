export const GrayCardIcon = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      role='img'
    >
      <rect
        y='7.5'
        width='40'
        height='25'
        rx='2.5'
        fill='black'
        fillOpacity='0.28'
      />
      <rect
        x='28.125'
        y='11.875'
        width='7.5'
        height='5'
        rx='0.625'
        fill='url(#paint0_linear_2_1970)'
      />
      <rect
        x='4.375'
        y='25.625'
        width='9.16667'
        height='2.5'
        rx='0.625'
        fill='url(#paint1_linear_2_1970)'
      />
      <rect
        x='15.4167'
        y='25.625'
        width='9.16667'
        height='2.5'
        rx='0.625'
        fill='url(#paint2_linear_2_1970)'
      />
      <rect
        x='26.4583'
        y='25.625'
        width='9.16667'
        height='2.5'
        rx='0.625'
        fill='url(#paint3_linear_2_1970)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_2_1970'
          x1='35.625'
          y1='12.5'
          x2='28.125'
          y2='16.875'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D9D9D9' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_2_1970'
          x1='13.5417'
          y1='25.9375'
          x2='9.49128'
          y2='31.713'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D9D9D9' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_2_1970'
          x1='24.5833'
          y1='25.9375'
          x2='20.533'
          y2='31.713'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D9D9D9' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_2_1970'
          x1='35.625'
          y1='25.9375'
          x2='31.5746'
          y2='31.713'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D9D9D9' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
      </defs>
    </svg>
  )
}
