import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { ConfigurationComponent } from '../ConfigurationComponent/ConfigurationComponent'
import { DomainComponent } from '../DomainComponent/DomainComponent'

export const PaymentMethodComponent = () => {
  return (
    <div className='px-40 flex flex-col space-y-8 bg-gray-0 pb-8'>
      <h1 className='type-headline-3 font-bold font-normal mt-8'>
        Payment methods
      </h1>
      <DividingLine />

      <div className='flex flex-col space-y-8'>
        <ConfigurationComponent />
        <DomainComponent />
      </div>
    </div>
  )
}
