// PaymentMethodToggle.tsx
import React from 'react'
import { ToggleSwitch } from '@toasttab/buffet-pui-toggle-switches'
import { PaymentIcons } from '../PaymentIcons/PaymentIcons'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { Alert } from '@toasttab/buffet-pui-alerts'

interface Props {
  paymentType: string
  label: string
  displayPreference?: 'ON' | 'OFF'
  onToggle: () => void
}

const PaymentMethodToggle: React.FC<Props> = ({
  paymentType,
  label,
  displayPreference = 'OFF',
  onToggle
}: Props) => {
  return (
    <>
      <div className='flex items-center mb-2 mt-4 px-4'>
        <PaymentIcons paymentType={paymentType} />
        <span className='ml-2 flex-grow'>{label}</span>
        <ToggleSwitch
          isActive={displayPreference === 'ON'}
          onChange={onToggle}
          testId={`toggle-switch-${paymentType}`}
        />
        <span className='ml-4'>
          {displayPreference === 'ON' ? 'On' : 'Off'}
        </span>
      </div>
      {paymentType === 'applePay' && displayPreference === 'ON' ? (
        <Alert className='w-full mt-4' variant='info'>
          You must register and verify all top-level domains and subdomains
          where you’ll use Apple Pay.
        </Alert>
      ) : (
        <></>
      )}
      <DividingLine />
    </>
  )
}

export default PaymentMethodToggle
