import React, { useEffect, useState } from 'react'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import PaymentMethodToggle from '../ReusableComponents/PaymentMethodToggle/PaymentMethodToggle'
import {
  PMDisplayConfiguration,
  PaymentMethodKey
} from '../../typedefs/payment-method-configs/PaymentMethodConfigs'

type Props = {
  initialPaymentMethods: {
    [key in PaymentMethodKey]: PMDisplayConfiguration | undefined
  }
  onUpdatePaymentMethods: (updatedPaymentMethods: {
    [key in PaymentMethodKey]: PMDisplayConfiguration | undefined
  }) => void
}

const PMDisplayConfigurationComponent: React.FC<Props> = ({
  initialPaymentMethods,
  onUpdatePaymentMethods
}: Props) => {
  const [paymentMethods, setPaymentMethods] = useState(initialPaymentMethods)

  useEffect(() => {
    setPaymentMethods(initialPaymentMethods)
  }, [initialPaymentMethods])

  const handleToggle = (paymentType: PaymentMethodKey) => {
    const updatedPaymentMethods = {
      ...paymentMethods,
      [paymentType]: {
        ...paymentMethods[paymentType]!,
        displayPreference:
          paymentMethods[paymentType]?.displayPreference === 'ON' ? 'OFF' : 'ON'
      }
    }
    setPaymentMethods(updatedPaymentMethods)
    onUpdatePaymentMethods(updatedPaymentMethods)
  }

  return (
    <div>
      <p className='type-subhead text-secondary mb-0 mt-6'>Payment Methods</p>
      <DividingLine />
      <PaymentMethodToggle
        paymentType='applePay'
        label='Apple Pay'
        displayPreference={paymentMethods?.applePay?.displayPreference}
        onToggle={() => handleToggle('applePay')}
      />
      <PaymentMethodToggle
        paymentType='googlePay'
        label='Google Pay'
        displayPreference={paymentMethods?.googlePay?.displayPreference}
        onToggle={() => handleToggle('googlePay')}
      />
      <PaymentMethodToggle
        paymentType='card'
        label='Keyed-In cards'
        displayPreference={paymentMethods?.card?.displayPreference}
        onToggle={() => handleToggle('card')}
      />
    </div>
  )
}

export default PMDisplayConfigurationComponent
