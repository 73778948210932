export type PMDisplayConfiguration = {
  displayPreference: 'ON' | 'OFF' | undefined
}

export type PaymentMethodConfig = {
  id: string
  name: string
  active: boolean
  isDefault: boolean
  card: PMDisplayConfiguration
  applePay: PMDisplayConfiguration
  googlePay: PMDisplayConfiguration
}

export type CreatePaymentMethodConfig = {
  name: string
  active?: boolean
  card: PMDisplayConfiguration
  applePay: PMDisplayConfiguration
  googlePay: PMDisplayConfiguration
}

export type SavePaymentMethodConfig = {
  id: string
  active?: boolean
  name: string
  card: PMDisplayConfiguration
  applePay: PMDisplayConfiguration
  googlePay: PMDisplayConfiguration
}

export type PaymentMethods = {
  card: PMDisplayConfiguration
  applePay: PMDisplayConfiguration
  googlePay: PMDisplayConfiguration
}

export type PaymentMethodKey = 'card' | 'applePay' | 'googlePay'

export type SelectedPaymentMethods = {
  [key in PaymentMethodKey]: PMDisplayConfiguration
}

export const defaultPaymentConfigurations: SelectedPaymentMethods = {
  card: { displayPreference: 'ON' },
  applePay: { displayPreference: 'OFF' },
  googlePay: { displayPreference: 'OFF' }
}
