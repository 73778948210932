export const GPayIcon = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      role='img'
    >
      <g clipPath='url(#clip0_147_10929)'>
        <path
          d='M29.2291 9.40397H10.5801C4.75231 9.40397 -0.0158997 14.1722 -0.0158997 20C-0.0158997 25.8278 4.75231 30.596 10.5801 30.596H29.2291C35.0569 30.596 39.8252 25.8278 39.8252 20C39.8252 14.1722 35.0569 9.40397 29.2291 9.40397Z'
          fill='white'
        />
        <path
          d='M29.2291 10.2623C30.5377 10.2623 31.8093 10.5219 33.0066 11.0305C34.1669 11.5232 35.2053 12.2278 36.106 13.1232C37.0013 14.0185 37.706 15.0623 38.1987 16.2225C38.7073 17.4199 38.9669 18.6914 38.9669 20C38.9669 21.3086 38.7073 22.5801 38.1987 23.7775C37.706 24.9377 37.0013 25.9762 36.106 26.8768C35.2106 27.7722 34.1669 28.4768 33.0066 28.9695C31.8093 29.4781 30.5377 29.7377 29.2291 29.7377H10.5801C9.27152 29.7377 7.99999 29.4781 6.80264 28.9695C5.64238 28.4768 4.60397 27.7722 3.70331 26.8768C2.80794 25.9815 2.10331 24.9377 1.61059 23.7775C1.10198 22.5801 0.842378 21.3086 0.842378 20C0.842378 18.6914 1.10198 17.4199 1.61059 16.2225C2.10331 15.0623 2.80794 14.0238 3.70331 13.1232C4.59867 12.2278 5.64238 11.5232 6.80264 11.0305C7.99999 10.5219 9.27152 10.2623 10.5801 10.2623H29.2291ZM29.2291 9.40397H10.5801C4.75231 9.40397 -0.0158997 14.1722 -0.0158997 20C-0.0158997 25.8278 4.75231 30.596 10.5801 30.596H29.2291C35.0569 30.596 39.8252 25.8278 39.8252 20C39.8252 14.1722 35.0569 9.40397 29.2291 9.40397Z'
          fill='#3C4043'
        />
        <path
          d='M18.9828 20.7522V23.9575H17.9656V16.0423H20.6623C21.3457 16.0423 21.9285 16.2701 22.4053 16.7257C22.8927 17.1813 23.1365 17.7376 23.1365 18.3946C23.1365 19.0674 22.8927 19.6237 22.4053 20.074C21.9338 20.5244 21.351 20.7469 20.6623 20.7469H18.9828V20.7522ZM18.9828 17.0171V19.7774H20.6835C21.0861 19.7774 21.4252 19.6396 21.6901 19.3694C21.9603 19.0992 22.098 18.7707 22.098 18.3999C22.098 18.0343 21.9603 17.7111 21.6901 17.4409C21.4252 17.1601 21.0914 17.0224 20.6835 17.0224H18.9828V17.0171Z'
          fill='#3C4043'
        />
        <path
          d='M25.796 18.3628C26.5484 18.3628 27.1417 18.5641 27.5762 18.9667C28.0106 19.3694 28.2278 19.9204 28.2278 20.6197V23.9575H27.2583V23.2051H27.2159C26.7974 23.825 26.2358 24.1323 25.5364 24.1323C24.9378 24.1323 24.4398 23.9575 24.0371 23.6025C23.6345 23.2475 23.4331 22.8078 23.4331 22.278C23.4331 21.7164 23.645 21.2714 24.0689 20.9429C24.4927 20.6091 25.0596 20.4449 25.7643 20.4449C26.3682 20.4449 26.8662 20.5561 27.253 20.7787V20.5455C27.253 20.1906 27.1152 19.8939 26.8345 19.6449C26.5537 19.3959 26.2252 19.274 25.849 19.274C25.2821 19.274 24.8318 19.5124 24.5033 19.9945L23.608 19.433C24.1007 18.7177 24.8318 18.3628 25.796 18.3628ZM24.4821 22.2939C24.4821 22.5588 24.5934 22.7813 24.8212 22.9561C25.0437 23.131 25.3086 23.221 25.6106 23.221C26.0398 23.221 26.4212 23.0621 26.755 22.7442C27.0888 22.4263 27.2583 22.0555 27.2583 21.6263C26.9404 21.3773 26.5007 21.2502 25.9338 21.2502C25.5205 21.2502 25.1762 21.3508 24.9007 21.5469C24.6199 21.7535 24.4821 22.0025 24.4821 22.2939Z'
          fill='#3C4043'
        />
        <path
          d='M33.759 18.5376L30.3682 26.3363H29.3192L30.5802 23.6078L28.3444 18.5376H29.4517L31.0623 22.4263H31.0835L32.6517 18.5376H33.759Z'
          fill='#3C4043'
        />
        <path
          d='M14.9367 20.1058C14.9367 19.7742 14.9071 19.4568 14.852 19.1517H10.5881V20.9L13.0437 20.9005C12.9441 21.4823 12.6236 21.9782 12.1325 22.3088V23.4431H13.5942C14.4477 22.6531 14.9367 21.4854 14.9367 20.1058Z'
          fill='#4285F4'
        />
        <path
          d='M12.133 22.3088C11.7261 22.5832 11.2022 22.7437 10.5892 22.7437C9.40507 22.7437 8.40056 21.9458 8.04083 20.8703H6.53301V22.0401C7.28003 23.5225 8.8154 24.5398 10.5892 24.5398C11.8151 24.5398 12.8451 24.1366 13.5947 23.4425L12.133 22.3088Z'
          fill='#34A853'
        />
        <path
          d='M7.89884 20.0025C7.89884 19.7005 7.94917 19.4086 8.04082 19.1342V17.9644H6.53301C6.22414 18.5774 6.05036 19.2693 6.05036 20.0025C6.05036 20.7358 6.22467 21.4277 6.53301 22.0407L8.04082 20.8709C7.94917 20.5964 7.89884 20.3045 7.89884 20.0025Z'
          fill='#FABB05'
        />
        <path
          d='M10.5892 17.2608C11.2583 17.2608 11.8575 17.4913 12.3306 17.9416L13.626 16.6473C12.8392 15.9146 11.8135 15.4648 10.5892 15.4648C8.81593 15.4648 7.28003 16.482 6.53301 17.9644L8.04083 19.1342C8.40056 18.0587 9.40507 17.2608 10.5892 17.2608Z'
          fill='#E94235'
        />
      </g>
      <defs>
        <clipPath id='clip0_147_10929'>
          <rect
            width='40'
            height='21.1921'
            fill='white'
            transform='translate(0 9.40397)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
