import {
  Table,
  Head,
  Body,
  Row,
  HeadingCell,
  Cell
} from '@toasttab/buffet-pui-table'
import './TableComponent.css'

import React from 'react'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'
import { TableComponentProps } from './TableComponentTypes'
import { ChevronRightIcon } from '@toasttab/buffet-pui-icons'

export const TableComponent: React.FC<TableComponentProps> = ({
  data,
  columns,
  showModalButton,
  onModalButtonClick
}) => {
  return (
    <Table variant='plain'>
      <Head>
        <Row>
          {columns.map((column, index) => (
            <HeadingCell key={index}>
              {column.heading}
              {column.tooltip && (
                <InfoTooltip size='sm' placement='right'>
                  {column.tooltip}
                </InfoTooltip>
              )}
            </HeadingCell>
          ))}
        </Row>
      </Head>
      <Body>
        {data?.map((row, rowIndex) => (
          <Row key={rowIndex}>
            {columns.map((column, colIndex) => (
              <Cell key={colIndex}>
                {column.render
                  ? column.render(row[column.dataKey])
                  : row[column.dataKey]}
              </Cell>
            ))}
            <Cell>
              {showModalButton && (
                <button
                  data-testid='modal-button'
                  className='rounded-full hover:bg-gray-0 focus-visible:shadow-focus'
                  onClick={() => {
                    if (onModalButtonClick) onModalButtonClick(row)
                  }}
                >
                  <ChevronRightIcon className='text-gray-666' />
                </button>
              )}
            </Cell>
          </Row>
        ))}
      </Body>
    </Table>
  )
}
